import "./index.less";

const Conduct = () => {
  return (
    <section id="conduct">
      <div className="conductLeft">
        <img src="https://img.cosvast.com/test/20240621home.png" alt="" />
      </div>
      <div className="conductRight">
        <div className="title">行为准则</div>
        <div className="desc">
          星图无疆相信，在企业发展过程中，高道德标准和诚信文化发挥着关键作用。因此，我们制定了星图无疆行为准则和星图无疆合作伙伴行为准则。
        </div>
        <div className="more">
          <span>了解更多</span>
          <span className="suffix">→</span>
        </div>
      </div>
    </section>
  );
};

export default Conduct;
