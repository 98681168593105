import { Carousel } from "antd";
import "./index.less";

const CultureSlider = () => {
  const list = [
    {
      img:'https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/zh-3-5dbfa39699d9bbf71d42a40186595419.jpg',
      title: '多元兼容',
      key:1,
      descs:["欣赏个体多样性，聚焦人的核心特质","全球视角，理解不同文化、观点和实践","善意假设，默认开放信任，有效合作"]
    },
    {
      img:'https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/zh-3-5dbfa39699d9bbf71d42a40186595419.jpg',
      title: '坦诚清晰',
      key:2,
      descs:["表达真实想法，不怕暴露问题，反对'向上管理'","准确、简洁、直接，少用抽象、模糊、空泛的词","就事论事，理性沟通，避免主观臆测和情绪化表达"]
    },
    {
      img:'https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/zh-3-5dbfa39699d9bbf71d42a40186595419.jpg',
      title: '求真务实',
      key:3,
      descs:["独立思考，刨根问底，找到本质","直接体验，深入事实，拿一手数据或信息","不自嗨，注重实际效果"]
    },
    {
      img:'https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/zh-3-5dbfa39699d9bbf71d42a40186595419.jpg',
      title: '敢为极致',
      key:4,
      descs:["敢于为了更好的结果明智地冒险，注重整体ROI","尝试多种可能性，在更大范围里找最优解","追求卓越，高标准，不仅做了，更要做好"]
    },
    {
      img:'https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/zh-3-5dbfa39699d9bbf71d42a40186595419.jpg',
      title: '共同成长',
      key:5,
      descs:["相信并认可使命和愿景，基于使命愿景自驱","面对短期波动有耐心、有韧性，共同解决问题","持续学习，不设边界，与组织一起成长"]
    },
    {
      img:'https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/zh-3-5dbfa39699d9bbf71d42a40186595419.jpg',
      title: '始终创业',
      key:6,
      descs:["保持创业心态，始终开创而不守成，创新而非依赖资源","敏捷有效，最简化流程，避免简单事情复杂化","对外敏锐谦逊，避免自满或优越感"]
    },

  ]
  return (
    <div id="CultureSlider">
      <Carousel dots={false} arrows>
        {list.map((item) => (
          <div className="sliderItem" key={item.key}>
          <img src={ item.img } alt="cosvast" />
          <div className="itemDesc">
              <div className="title">{ item.title}</div>
              <ul className="desc-container">
                {item.descs.map((i,id) => (
                  <li className="desc" key={id}>{i}</li>
                ))}
            </ul>
          </div>
        </div>
        ))}
   
      </Carousel>
    </div>
  );
};

export default CultureSlider;
