import "./index.less";

const Contact = () => {
  return (
    <section id="contact">
      <div className="title">联系我们</div>
      <div className="contact-wrapper">
        <div className="contact-item">
          <div className="item-title">媒体问询</div>
          <a href="Mailto:interview@cosvast.com">interview@coscast.com</a>
          {/* <a href="Mailto:interview@cosvast.com">暂无</a> */}
        </div>
        <div className="contact-item">
          <div className="item-title">招聘相关</div>
          <a href="Mailto:hr@cosvast.com">hr@cosvast.com</a>
        </div>
        <div className="contact-item">
          <div className="item-title">广告合作</div>
          <a href="Mailto:interview@cosvast.com">暂无</a>
        </div>
        <div className="contact-item">
          <div className="item-title">廉洁举报</div>
          <a href="Mailto:compliance@cosvast.com">compliance@cosvast.com</a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
