import Header from "@/components/Header";
import Footer from "@/components/Footer/index.jsx";
import Background from "@/components/Background/index.jsx";
import error404 from "@/static/images/404.png";

const Page404 = () => {
  return (
    <div className="notFoundContainer">
      <Background />
      <div style={{ marginBottom: ".6rem" }}>
        <Header />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={error404} style={{ width: "60%" }} alt="页面开发中～" />
      </div>

      <div style={{ marginTop: ".6rem" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Page404;
