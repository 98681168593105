import { useEffect } from "react";

function useBodyStyle(styles) {
  useEffect(() => {
    const prevStyles = Object.keys(styles).reduce((acc, key) => {
      acc[key] = document.body.style[key];
      return acc;
    }, {});

    Object.entries(styles).forEach(([key, value]) => {
      document.body.style[key] = value;
    });

    return () => {
      Object.entries(prevStyles).forEach(([key, value]) => {
        document.body.style[key] = value;
      });
    };
  }, [styles]);
}

export default useBodyStyle;
