import { Carousel } from "antd";
import React, { useState, useEffect } from "react";
import "./index.less";

const Slider = () => {
  const [itemWidth, setItemWidth] = useState("100%"); // 默
  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 750 && width >= 500) {
      setItemWidth(width);
    } else {
      setItemWidth("100%"); // 超过750像素，恢复默认
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // 初始化时调用一次
    return () => {
      window.removeEventListener("resize", handleResize); // 组件卸载时移除监听器
    };
  }, []);
  const list = [
    {
      img: "https://img.cosvast.com/cosvast/3511416529674b0a9c4b691b2ece516a.png_tplv-0es2k971ck-image%20(3).png",
      key: "1",
    },
    {
      img: "https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/3-9ea22bd593086f432ab75ee6c95c37de.png",
      key: "2",
    },
    {
      img: "https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/1-b4afd269ffb0ba19bd1dd33e3ed5cec3.png",
      key: "3",
    },
    {
      img: "https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/2-468bb0555d827d48bce4e178f085bf90.png",
      key: "4",
    },
  ];

  return (
    <div id="SliderStyle">
      <Carousel autoplay dots={true}>
        {list.map((item) => (
          <div className="sliderItem" key={item.key}>
            <img style={{ width: itemWidth }} src={item.img} alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
