import { useState, useEffect } from "react";
function useScroll() {
  const [scrollDistance, setScrollDistance] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null); // 'up' or 'down'
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const distance = Math.abs(scrollTop - lastScrollTop);
      const direction = scrollTop > lastScrollTop ? "down" : "up";

      setScrollDistance(distance);
      setScrollDirection(direction);
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return { scrollDistance, scrollDirection };
}

export default useScroll;
