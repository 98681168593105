import { useState, useEffect } from "react";
// import { history } from "umi";
import { useNavigate } from "react-router-dom";
import "./index.less";
import logo from "@/static/images/cosvastblack.png";
const Header = () => {
  const navigate = useNavigate();
  let myHeight = 300;
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(window.scrollY <= myHeight);
  const [rightStatus, setRight] = useState(false);
  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const visible =
        prevScrollPos > currentScrollPos || currentScrollPos <= myHeight;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setVisible(currentScrollPos <= myHeight);
      }, 1000); // 2秒延迟显
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, [prevScrollPos, myHeight]);
  const handleExpend = () => {
    setRight(!rightStatus);
  };
  return (
    <div className={`headerContainer ${!visible ? "headerHide" : ""}`}>
      <div
        className="logo"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="cosvast" />
      </div>
      <div
        className={`menu-toggle ${rightStatus ? "expended" : ""}`}
        onClick={handleExpend}
      >
        <div className="toggle-bar toggle-bar-1"></div>
        <div className="toggle-bar toggle-bar-2"></div>
        <div className="toggle-bar toggle-bar-3"></div>
        <div className="toggle-bar toggle-bar-4"></div>
      </div>
      <div className="headerRight">
        <div
          className="nav-item"
          onClick={() => {
            navigate("/");
          }}
        >
          关于我们
        </div>
        <div
          className="nav-item"
          onClick={() => {
            navigate("/product");
          }}
        >
          我们的产品
        </div>
        <div
          className="nav-item"
          onClick={() => {
            navigate("/news");
          }}
        >
          新闻动态
        </div>
        <div
          className="nav-item"
          onClick={() => {
            navigate("/entity");
          }}
        >
          企业社会责任
        </div>
        <div
          className="nav-item"
          onClick={() => {
            navigate("/joinUs");
          }}
        >
          加入我们
        </div>
      </div>
    </div>
  );
};

export default Header;
