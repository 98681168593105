import Home from "./pages/Home/index";
import Product from "./pages/Product";
// import NotFound from "./pages/NotFound";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/product",
    component: Product,
    // exact: true,
  },
  // {
  //   path: "/about",
  //   component: About,
  // },
  // {
  //   component: NotFound,
  // },
];

export default routes;
