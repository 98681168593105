import Header from "@/components/Header";
// import Footer from "@/components/Footer/index.jsx";
import useBodyStyle from "@/hooks/useBodyStyle";
// import Background from "@/components/Background/index.jsx";
import "./index.less";
import { useState } from "react";

const Product = () => {
  useBodyStyle({ overflow: "hidden" });
  const [activeSlide, setActiveSlide] = useState(false);
  const arr = [
    {
      img: "https://p9-bd-official.byteimg.com/img/bytedance-cn/dd665e447cd09c339875f9580a5478bd~noop.image",
      slideImg:
        "https://p6-bd-official.byteimg.com/img/bytedance-cn/4ac74bbefc4455d0b350fff1fcd530c7~noop.image",
      title: "今日头条",
      desc: `今日头条是一个通用信息平台，致力于连接人与信息，让优质丰富的信息得到高效精准的分发，帮助用户看见更大的世界。
                  今日头条目前拥有推荐引擎、搜索引擎、关注订阅和内容运营等多种分发方式，囊括图文、视频、问答、微头条、专栏、小说、直播、音频和小程序等多种内容体裁，并涵盖科技、体育、健康、美食、教育、三农、国风等超过100个内容领域。`,
      link: "https://www.toutiao.com/.",
    },
    {
      slideImg:
        "https://p3-bd-official.byteimg.com/img/bytedance-cn/4bcac7e2843bd01c3158dcaefda77ada~noop.image",
      img: "https://p6-bd-official.byteimg.com/img/bytedance-cn/86435a19149dab662ca1f4238f694163~noop.image",
      title: "",
      desc: "",
      link: "",
    },
    {
      slideImg:
        "https://p6-bd-official.byteimg.com/img/bytedance-cn/48a81d5e4fd0c5a1d0114b307f125c23~noop.image",
      img: "https://p26-bd-official.byteimg.com/img/bytedance-cn/a334227dff9025ba8ec0ebeb08c96f1c~noop.image",
      title: "",
      desc: "",
      link: "",
    },
    {
      slideImg:
        "https://p3-bd-official.byteimg.com/img/bytedance-cn/a863b4860b58e31a4b536cb614f89a7c~noop.image",
      img: "https://p26-bd-official.byteimg.com/img/bytedance-cn/e0d12e68d243d01f32a40c3979c6bdca~noop.image",
      title: "",
      desc: "",
      link: "",
    },
  ];

  return (
    <div className="productContainer">
      <Header />
      <div className="products-carouse">
        <div
          className="main-wrapper"
          style={{ transform: `translateY(-${100 * activeSlide}%)` }}
        >
          {arr.map((item, index) => (
            <div
              style={{
                backgroundImage: `url(${item.img})`,
              }}
              key={index}
              className="item-wrapper"
            >
              <div className="item-left">
                <div className="title-wrapper">
                  <img
                    src="https://p6-bd-official.byteimg.com/img/bytedance-cn/4ac74bbefc4455d0b350fff1fcd530c7~noop.image"
                    alt=""
                  />
                  <div className="title">今日头条</div>
                </div>
                <p className="desc">
                  {item.desc}
                  <span>
                    <br />
                    <br />
                    更多信息，请访问：{" "}
                    <span>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.link}
                      </a>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={`slide-wrapper`}>
          {arr.map((item, index) => (
            <div
              key={index}
              className={`slide-item ${activeSlide === index ? "active" : ""}`}
              onClick={() => {
                setActiveSlide(index);
              }}
            >
              <img src={item.slideImg} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
