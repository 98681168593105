import useScroll from "@/hooks/useScroll.js";
import "./index.less";

const Background = () => {
  const { scrollDistance } = useScroll();
  return (
    <div className="backContainer">
      <div className="bg-ellipse-full-wrapper">
        <div className="bg-ellipse-wrapper">
          <div
            className="bg-ellipse bg-ellipse-1"
            style={{
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 41.09
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-2"
            style={{
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 61.635
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-3"
            style={{
              // transform: "translate3d(0px, 20.545px, 0px)",
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 20.545
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-4"
            style={{
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 41.09
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-5"
            style={{
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 61.635
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-6"
            style={{
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 20.545
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-7"
            style={{
              // transform: "translate3d(0px, 41.09px, 0px)",
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 41.09
              }px, 0px)`,
            }}
          ></div>
          <div
            className="bg-ellipse bg-ellipse-8"
            style={{
              transform: `translate3d(0px, ${
                scrollDistance * 2 + 61.635
              }px, 0px)`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Background;
