import Header from "@/components/Header";
import Footer from "@/components/Footer/index.jsx";
import Culture from "@/pages/Home/component/Culture";
import Conduct from "@/pages/Home/component/Conduct";
import History from "@/pages/Home/component/History";
import Contact from "@/pages/Home/component/Contact";
import Slider from "@/components/Slider/index.jsx";
import Background from "@/components/Background/index.jsx";
import "./index.less";

const Home = () => {
  return (
    <div className="homeContainer">
      <Background />
      <Header />
      <Slider />
      <Culture />
      <Conduct />
      <History />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
